import "./App.css";
import React, { useState, useEffect, useRef } from 'react';
import Header from "./components/Header/Header";
import axios from 'axios';
import { onValue, ref } from 'firebase/database';
import { db } from './firebase/firebase';
import FilterColumn from "./components/FilterColumn/FilterColumn";
import CardColumn from "./components/CardColumn/CardColumn";
import ResultColumn from "./components/ResultColumn/ResultColumn";

 
const App = () => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isSingleCompanyOpen, setIsSingleCompanyOpen] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [selectedCounty, setSelectedCounty] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [radius, setRadius] = useState('5');
    const [searchResult, setSearchResult] = useState(null);
    const [originalCompanies, setOriginalCompanies] = useState([]);
    const [counties, setCounties] = useState([]);
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState('');
    const [filterCommercial, setFilterCommercial] = useState(false);
    const [filterResidential, setFilterResidential] = useState(false);
    const [filterBoth, setFilterBoth] = useState(false);
    // const [filterCCEFResidential, setFilterCCEFResidential] = useState(false);
    const [filterCCEFRenu, setFilterCCEFRenu] = useState(false);
    const [filterCCEFOnBill, setFilterCCEFOnBill] = useState(false);
    const [filterCCEFCommercial, setFilterCCEFCommercial] = useState(false);
    const [filterCEOTaxCredits, setFilterCEOTaxCredits] = useState(false);
    const [filterEnglish, setFilterEnglish] = useState(false);
    const [filterSpanish, setFilterSpanish] = useState(false);
    const [filterOther, setFilterOther] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const validateData = (data) => {
        return data.filter(company =>
            !(company.company === "Salesforce import is empty.") && company.status === 'Active'
            // !(company.company === "Salesforce import is empty.")
        );
    };

    // function calling the db and grabbing what entries are there
    useEffect(() => {
        const query = ref(db, 'companies');
        return onValue(query, (snapshot) => {
            const data = snapshot.val();

            if (snapshot.exists()) {
                const companyArray = validateData(Object.values(data));
                setCompanies(companyArray);
                setOriginalCompanies(companyArray); // Store the original companies
                setServices(getUniqueServices(companyArray));  // Populate services from all companies
                setCounties(getUniqueCounties(companyArray));  // Populate counties from all companies
            }
        });
    }, []);

    // function to handle clicking one of the small cards
    // sets the selected company to the one chosen so the larger card pulls in the correct information
    // sets that you've opened an individual card
    const handleSmallCardClick = (company) => {
        setSelectedCompany(company);
        setIsSingleCompanyOpen(true);
    };

    // detects when someone clicks outside of the cards
    const singleCompanyRef = useRef();

    const handleZipSearch = (zipCode, radius) => {
        setZipCode(zipCode); 
        setRadius(radius); 
        const apiKey = process.env.REACT_APP_ZIP_CODE_API_KEY; 
        const apiUrl = `https://www.zipcodeapi.com/rest/${apiKey}/radius.json/${zipCode}/${radius}/miles?minimal`;

        axios.get(apiUrl)
        .then((response) => {
            // Handle the API response here
            setSearchResult(response.data);
    
            // Filter companies based on matching zip codes
            const matchingCompanies = companies.filter((company) =>
                response.data.zip_codes.includes(company.zip)
            );
    
            // Display the matching companies
            setCompanies(matchingCompanies);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    const toTitleCase = (str) => {
        return str.replace(
            /\w\S*/g,
            (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        );
    };

    useEffect(() => {
        const uniqueCounties = getUniqueCounties(originalCompanies);
        setCounties(uniqueCounties);
    }, [originalCompanies]);

    const getUniqueCounties = (companies) => {
        if (!companies) return [];
    
        // Flatten the array of county strings into a single array
        const allCounties = companies.reduce((acc, company) => {
            if (company.counties && typeof company.counties === 'string') {
                return acc.concat(company.counties.split(/[,\/;]/).map(county => county.trim()));
            }
            return acc;
        }, []);
    
        // Map over all counties to apply title casing
        const titledCounties = allCounties.map(county => toTitleCase(county));
    
        // Remove duplicates and sort alphabetically
        const uniqueCounties = [...new Set(titledCounties)].sort();
    
        // Ensure "Statewide" is the first item if it exists
        const index = uniqueCounties.indexOf("Statewide");
        if (index > -1) {
            uniqueCounties.splice(index, 1); // Remove "Statewide" from its original position
            uniqueCounties.unshift("Statewide"); // Add "Statewide" to the beginning
        }
    
        return uniqueCounties;
    };
    

    const getUniqueServices = (companies) => {
        if (!companies) return [];
        const allServices = companies.reduce((acc, company) => {
            const services = [];
            if (company.rez_serve && typeof company.rez_serve === 'string') {
                services.push(...company.rez_serve.split(/[,\/;]/).map(service => toTitleCase(service.trim())));
            }
            if (company.com_serve && typeof company.com_serve === 'string') {
                services.push(...company.com_serve.split(/[,\/;]/).map(service => toTitleCase(service.trim())));
            }
            if (company.hp_used && typeof company.hp_used === 'string') {
                services.push(...company.hp_used.split(/[,\/;]/).map(service => toTitleCase(service.trim())));
            }
            return acc.concat(services);
        }, []);
        
        return [...new Set(allServices)].sort(); // Remove duplicates and sort alphabetically
    };

    useEffect(() => {
        // Ensure there are companies to filter before proceeding
        if (!originalCompanies) return;
    
        // Apply filters to the list of companies
        const filteredCompanies = filterCompanies(originalCompanies);
    
        // Update the list of companies in state
        setCompanies(filteredCompanies);
    
        // Check if the number of filtered companies has changed to avoid unnecessary updates
        if (filteredCompanies.length !== companies.length) {
            // Recalculate and update counties based on the newly filtered companies
            setCounties(getUniqueCounties(filteredCompanies));
            
            // Recalculate and update services based on the newly filtered companies
            setServices(getUniqueServices(filteredCompanies));
        } else {
            setServices(getUniqueServices(originalCompanies))
        }
    }, [
        originalCompanies,
        selectedCounty, 
        selectedService, 
        searchResult,
        filterCommercial, 
        filterResidential, 
        filterBoth, 
        // filterCCEFResidential, 
        filterCCEFRenu,
        filterCCEFOnBill,
        filterCCEFCommercial, 
        filterCEOTaxCredits, 
        filterEnglish, 
        filterSpanish, 
        filterOther
    ]);
    
    const filterCompanies = (companies) => {
        return companies.filter(company => {
            const selectedServiceLower = selectedService ? selectedService.toLowerCase() : '';
            const selectedCountyLower = selectedCounty ? selectedCounty.toLowerCase() : '';
    
            const companyMatchesService = (
                (company.rez_serve && typeof company.rez_serve === 'string' && company.rez_serve.toLowerCase().includes(selectedServiceLower)) ||
                (company.com_serve && typeof company.com_serve === 'string' && company.com_serve.toLowerCase().includes(selectedServiceLower)) ||
                (company.hp_used && typeof company.hp_used === 'string' && company.hp_used.toLowerCase().includes(selectedServiceLower))
            );
    
            const countyMatch = selectedCounty ? 
                (company.counties && typeof company.counties === 'string' && company.counties.toLowerCase().includes(selectedCountyLower)) : 
                true;
    
            const zipMatch = searchResult ? 
                searchResult.zip_codes.includes(company.zip) : 
                true;
    
            const isCommercial = company.com_serve;
            const isResidential = company.rez_serve;
            const isBoth = isCommercial && isResidential;
    
            const matchesCommercial = filterCommercial && isCommercial;
            const matchesResidential = filterResidential && isResidential;
            const matchesBoth = filterBoth && isBoth;
    
            // const matchesCCEFResidential = filterCCEFResidential && company.rez_serve;
            const rezProgArray = company.rez_prog ? company.rez_prog.split(/[,\/;]/).map(prog => prog.trim().toLowerCase()) : [];
            const matchesCCEFRenu = filterCCEFRenu && rezProgArray.includes('renu');
            const matchesCCEFOnBill = filterCCEFOnBill && rezProgArray.includes('on-bill repayment');

            const matchesCCEFCommercial = filterCCEFCommercial && company.com_serve;
            const matchesCEOTaxCredits = filterCEOTaxCredits && company.hp_used;
    
            const languagesArray = company.languages ? company.languages.split(',').map(lang => lang.trim()) : [];
            const matchesEnglish = filterEnglish && languagesArray.includes("English");
            const matchesSpanish = filterSpanish && languagesArray.includes("Spanish");
            const matchesOther = filterOther && languagesArray.includes("Other");
    
            // return (!selectedService || companyMatchesService) && countyMatch && zipMatch &&
            //        ((matchesCommercial || matchesResidential || matchesBoth) || (!filterCommercial && !filterResidential && !filterBoth)) &&
            //        ((matchesCCEFResidential || matchesCCEFCommercial || matchesCEOTaxCredits) || (!filterCCEFResidential && !filterCCEFCommercial && !filterCEOTaxCredits)) &&
            //        ((matchesEnglish || matchesSpanish || matchesOther) || (!filterEnglish && !filterSpanish && !filterOther));

            return (!selectedService || companyMatchesService) && countyMatch && zipMatch &&
                   ((matchesCommercial || matchesResidential || matchesBoth) || (!filterCommercial && !filterResidential && !filterBoth)) &&
                   ((matchesCCEFRenu || matchesCCEFOnBill || matchesCCEFCommercial || matchesCEOTaxCredits) || (!filterCCEFRenu && !filterCCEFOnBill && !filterCCEFCommercial && !filterCEOTaxCredits)) &&
                   ((matchesEnglish || matchesSpanish || matchesOther) || (!filterEnglish && !filterSpanish && !filterOther));
        });
    };
    
    const resetFilters = () => {
        setSelectedService('');
        setSelectedCounty('');
        setFilterCommercial(false);
        setFilterResidential(false);
        setFilterBoth(false);
        // setFilterCCEFResidential(false);
        setFilterCCEFRenu(false);
        setFilterCCEFOnBill(false);
        setFilterCCEFCommercial(false);
        setFilterCEOTaxCredits(false);
        setFilterEnglish(false);
        setFilterSpanish(false);
        setFilterOther(false);
        setZipCode('');
        setRadius('5');
        setCompanies(originalCompanies);
        setServices(getUniqueServices(originalCompanies)); 
        setCounties(getUniqueCounties(originalCompanies));
    };

    const applyFilters = () => {
        const filteredCompanies = filterCompanies(originalCompanies);
        setCompanies(filteredCompanies);
        setIsFilterOpen(false);
    };

    const showFilterColumn = () => {
        setIsFilterOpen(true);
    };

    const hideFilterColumn = () => {
        setIsFilterOpen(false);
    };

    const showCardColumn = () => {
        setIsSingleCompanyOpen(false);
    };

    const handleCompanySelection = (companyName) => {
        if (companyName === '') {
            setCompanies(filterCompanies(originalCompanies));
            setIsSingleCompanyOpen(false)
        } else {
            const selectedCompany = originalCompanies.find(company => company.company === companyName);
            setSelectedCompany(selectedCompany);
            setCompanies([selectedCompany]);
            setIsFilterOpen(false);
        }
    };

    return (
        <section>
            <Header showFilterColumn={showFilterColumn} isFilterOpen={isFilterOpen} hideFilterColumn={hideFilterColumn} />
            
            <div className="desk">
                <div className="main-content-row">
                    <FilterColumn handleZipSearch={handleZipSearch} 
                        counties={counties} handleCountyChange={setSelectedCounty} 
                        selectedCounty={selectedCounty} 
                        services={services} 
                        handleServiceChange={setSelectedService} 
                        selectedService={selectedService}
                        filterCommercial={filterCommercial}
                        setFilterCommercial={setFilterCommercial}
                        filterResidential={filterResidential}
                        setFilterResidential={setFilterResidential}
                        filterBoth={filterBoth} 
                        setFilterBoth={setFilterBoth} 
                        // filterCCEFResidential={filterCCEFResidential}
                        // setFilterCCEFResidential={setFilterCCEFResidential}
                        filterCCEFRenu={filterCCEFRenu}
                        setFilterCCEFRenu={setFilterCCEFRenu}
                        filterCCEFOnBill={filterCCEFOnBill}
                        setFilterCCEFOnBill={setFilterCCEFOnBill}
                        filterCCEFCommercial={filterCCEFCommercial}
                        setFilterCCEFCommercial={setFilterCCEFCommercial}
                        filterCEOTaxCredits={filterCEOTaxCredits}
                        setFilterCEOTaxCredits={setFilterCEOTaxCredits}
                        filterEnglish={filterEnglish}
                        setFilterEnglish={setFilterEnglish}
                        filterSpanish={filterSpanish}
                        setFilterSpanish={setFilterSpanish}
                        filterOther={filterOther}
                        setFilterOther={setFilterOther}
                        resetFilters={resetFilters}
                        zipCode={zipCode}
                        radius={radius}
                        setZipCode={setZipCode}
                        setRadius={setRadius}
                        companyNames={companies.map(company => company.company).sort()}
                        handleCompanySelection={handleCompanySelection}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        />
                    <CardColumn companies={companies} handleSmallCardClick={handleSmallCardClick}/>
                    <ResultColumn isSingleCompanyOpen={isSingleCompanyOpen} singleCompanyRef={singleCompanyRef} selectedCompany={selectedCompany} toTitleCase={toTitleCase} /*handleSingleCompanyClose={handleSingleCompanyClose} *//>
                </div>
            </div>

            <div className="mobile">
                <div className="main-content-row">
                    {isFilterOpen ? (
                        <div className="filter-column-container">
                            <FilterColumn handleZipSearch={handleZipSearch} 
                                counties={counties} handleCountyChange={setSelectedCounty} 
                                selectedCounty={selectedCounty} 
                                services={services} 
                                handleServiceChange={setSelectedService} 
                                selectedService={selectedService}
                                filterCommercial={filterCommercial}
                                setFilterCommercial={setFilterCommercial}
                                filterResidential={filterResidential}
                                setFilterResidential={setFilterResidential}
                                filterBoth={filterBoth} 
                                setFilterBoth={setFilterBoth} 
                                // filterCCEFResidential={filterCCEFResidential}
                                // setFilterCCEFResidential={setFilterCCEFResidential}
                                filterCCEFRenu={filterCCEFRenu}
                                setFilterCCEFRenu={setFilterCCEFRenu}
                                filterCCEFOnBill={filterCCEFOnBill}
                                setFilterCCEFOnBill={setFilterCCEFOnBill}
                                filterCCEFCommercial={filterCCEFCommercial}
                                setFilterCCEFCommercial={setFilterCCEFCommercial}
                                filterCEOTaxCredits={filterCEOTaxCredits}
                                setFilterCEOTaxCredits={setFilterCEOTaxCredits}
                                filterEnglish={filterEnglish}
                                setFilterEnglish={setFilterEnglish}
                                filterSpanish={filterSpanish}
                                setFilterSpanish={setFilterSpanish}
                                filterOther={filterOther}
                                setFilterOther={setFilterOther}
                                resetFilters={resetFilters}
                                zipCode={zipCode}
                                radius={radius}
                                setZipCode={setZipCode}
                                setRadius={setRadius}
                                applyFilters={applyFilters}
                                companyNames={companies.map(company => company.company).sort()}
                                handleCompanySelection={handleCompanySelection}
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                            />
                        </div>
                    ) : isSingleCompanyOpen ? (
                        <ResultColumn isSingleCompanyOpen={isSingleCompanyOpen} singleCompanyRef={singleCompanyRef} selectedCompany={selectedCompany} toTitleCase={toTitleCase} showCardColumn={showCardColumn} /*handleSingleCompanyClose={handleSingleCompanyClose} *//>
                    ) : (
                        <CardColumn companies={companies} handleSmallCardClick={handleSmallCardClick} />
                    )}
                </div>
            </div>
        </section>
    )
}
 
export default App